.icon {
  width: 80px;
  width: 80px;
  transition: fill 200ms ease;
  fill: var(--ui-main);
}
@media only screen and (min-width: 760px) {
  .icon {
    width: 62px;
    width: 62px;
  }
}
