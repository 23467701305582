.list {
  // width: clamp(400px, 50vw, 450px);
  width: 100%;
  max-width: 560px;
  position: relative;

  padding: 32px 24px 32px 24px;
  margin: 0 auto;
  // height: 50vh;
  // overflow: auto;
  min-height: 0;
  min-width: 0;
}

/* List item */

.item {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 6px 16px;
}
// .item:after {
//   content: "";
//   display: block;
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   height: 2px;
//   border-radius: 1px;
//   background: var(--ui-secondary);
// }

.item:last-of-type {
  margin-bottom: 8px;
}
.item:last-of-type:after {
  display: none;
}
// .item:first-of-type {
//   margin-top: 8px;
// }

.item__title {
  font-size: var(--body-text-size);
  font-weight: 500;
  margin-bottom: 8px;
}
.item__description {
  font-size: var(--body-text-size);
  opacity: 0.8;
}
.item__duration {
  font-size: var(--small-text-size);
}
.item__link {
  text-decoration: none;
  color: var(--main-text);
  padding: 24px 24px;
  width: 100%;
}

.item--rest {
  opacity: 0.7;
  position: relative;
}

.item--rest .item__title {
  font-weight: 400;
  font-size: var(--small-text-size);
  background: white;
  padding: 0 10px 0 0;
  color: var(--secondary-text);
}
.item--rest .item__title:after {
  content: "";
  position: absolute;
  z-index: -1;
  top: 40%;
  left: 0;
  right: 0;
  // transform: translateY(-45%);
  z-index: -1;
  border-top: solid 1px var(--ui-secondary);
  margin: 0 32px;
}
.item--rest .item__duration {
  background: white;
  padding: 0 0 0 16px;
  font-size: var(--small-text-size);
  color: var(--secondary-text);
}
.item--button {
  position: relative;
  // border: 1px solid transparent;
  background: white;
  border-radius: 16px;
  margin-bottom: 16px;
  padding: 0;
  box-shadow: 0 1px 1px rgba(38, 41, 57, 0.08), 0 2px 2px rgba(38, 41, 57, 0.07),
    0 4px 4px rgba(38, 41, 57, 0.05);
  transition: all 100ms;
}
.item--button:after {
  display: none;
}
.item--button:hover {
  top: -1px;
  // background: var(--ui-tertiary);
  // border: 1px solid var(--ui-secondary);
  box-shadow: 0 1px 1px rgba(38, 41, 57, 0.15), 0 2px 2px rgba(38, 41, 57, 0.13),
    0 4px 4px rgba(38, 41, 57, 0.11), 0 8px 8px rgba(38, 41, 57, 0.09);
}
