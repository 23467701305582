.app {
  --main-text: rgba(38, 41, 57, 1);
  --secondary-text: rgba(38, 41, 57, 0.75);
  --main-bg: rgba(234, 240, 242, 1);
  --ui-main: rgb(58, 47, 40);
  --ui-secondary: rgba(184, 187, 199, 0.5);
  --ui-tertiary: rgb(255, 243, 239);
  --top-gradient: rgba(192, 130, 112, 0.4);
  --bottom-gradient: rgba(255, 241, 114, 0.6);
  --loader-bg: rgba(255, 251, 231, 1);
}
// orange bg rgba(255, 217, 82, 1)
// blue bg rgba(165, 255, 233, 1)
// blue bottom gradient rgba(126, 255, 209, 0.6)
// blue top gradient rgba(40, 178, 255, 0.5)
.theme {
  min-height: 100vh;
  transition: background-color 300ms ease, background-image 300ms ease;
  background: radial-gradient(
      150% 100% at -15vw -45vh,
      var(--top-gradient) 0%,
      transparent 100%
    ),
    radial-gradient(
      90% 80% at 50vw 110vh,
      var(--bottom-gradient) 0%,
      transparent 100%
    ),
    var(--main-bg);
}
.theme--light {
  --main-text: rgba(38, 41, 57, 1);
  --secondary-text: rgba(38, 41, 57, 0.75);
  --main-bg: rgba(234, 240, 242, 1);
  --ui-main: rgba(38, 41, 57, 1);
  --ui-secondary: rgba(184, 187, 199, 0.5);
  --ui-tertiary: rgb(255, 243, 239);
  --top-gradient: rgba(255, 255, 255, 0.863);
  --bottom-gradient: rgba(86, 98, 167, 0.123);
  --loader-bg: rgba(255, 251, 231, 1);
}
// .theme--dark {
// }
.theme--exercise--active {
  --main-text: rgba(88, 37, 0, 1);
  --secondary-text: rgba(88, 37, 0, 0.75);
  --main-bg: rgba(255, 217, 82, 1);
  --ui-main: rgba(255, 107, 0, 0.75);
  --ui-secondary: rgba(88, 37, 0, 0.2);
  --ui-tertiary: rgba(88, 37, 0, 0.1);
  --top-gradient: rgba(255, 107, 62, 0.719);
  --bottom-gradient: rgba(255, 244, 148, 0.925);
  --loader-bg: rgba(255, 107, 0, 0.24);
}
.theme--rest--active {
  --main-text: rgba(0, 37, 92, 0.8);
  --secondary-text: rgba(0, 37, 92, 0.6);
  --main-bg: rgba(165, 255, 233, 1);
  --ui-main: rgba(0, 191, 234, 0.75);
  --ui-secondary: rgba(0, 37, 92, 0.2);
  --ui-tertiary: rgba(0, 37, 92, 0.1);
  --top-gradient: rgba(25, 175, 255, 0.644);
  --bottom-gradient: rgba(126, 255, 223, 0.788);
  --loader-bg: rgba(0, 191, 234, 0.24);
}
.theme--starting--active {
  --main-text: rgba(38, 41, 57, 1);
  --secondary-text: rgba(38, 41, 57, 0.75);
  --main-bg: rgb(238, 244, 247);
  --ui-main: rgba(38, 41, 57, 0.6);
  --ui-secondary: rgba(184, 187, 199, 0.5);
  --ui-tertiary: rgb(255, 243, 239);
  --top-gradient: rgba(255, 255, 255, 0.966);
  --bottom-gradient: rgba(96, 127, 230, 0.363);
  --loader-bg: rgba(255, 251, 231, 1);
}
.theme--final--active {
  --main-text: rgba(0, 37, 92, 1);
  --secondary-text: rgba(0, 37, 92, 0.75);
  --main-bg: rgba(165, 255, 233, 1);
  --ui-main: rgba(0, 191, 234, 0.75);
  --ui-secondary: rgba(0, 37, 92, 0.2);
  --ui-tertiary: rgba(0, 37, 92, 0.1);
  --top-gradient: rgba(40, 178, 255, 0.5);
  --bottom-gradient: rgba(126, 255, 209, 0.6);
  --loader-bg: rgba(0, 191, 234, 0.24);
}
