/* Base */
html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
/* Remove outline for non-keyboard :focus */
*:focus:not(.focus-visible) {
  outline: none;
}
.app {
  overflow: hidden;
}
