.bg-wrapper {
  // transform: skewY(20deg);
  position: absolute;
  overflow: hidden;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--main-bg);
  // transition: opacity 2s ease-out, background-color 300ms ease-out;
  transition: all 200ms ease-out;
}
// .bg-wrapper:after,
// .bg-wrapper:before {
//   content: "";
//   display: block;
//   position: absolute;
//   border-radius: 50%;
//   transition: all 200ms ease-out;
// }
// .bg-wrapper:before {
//   top: clamp(-700px, -40vw, -500px);
//   left: clamp(-1000px, -65vw, -550px);

//   width: clamp(1100px, 160vw, 3100px);
//   height: clamp(1200px, 60vw, 1400px);

//   background: radial-gradient(
//     50% 50% at 50% 50%,
//     var(--top-gradient) 60%,
//     transparent 100%
//   );
// filter: blur(100px);
// }
.bg-wrapper:after {
  right: -100px;
  bottom: -500px;
  left: -100px;

  height: 900px;

  background: radial-gradient(
    50% 50% at 50% 50%,
    var(--bottom-gradient) 60%,
    transparent 100%
  );
}
// .bg-wrapper--on-exercise:before,
// .bg-wrapper--on-rest:before {
//   content: "";
//   display: block;
//   position: absolute;
//   width: clamp(1100px, 160vw, 3100px);
//   height: clamp(1200px, 60vw, 1400px);
//   left: clamp(-1000px, -65vw, -550px);
//   top: clamp(-700px, -40vw, -500px);
//   border-radius: 50%;
// }
// .bg-wrapper--on-exercise:before {
//   background: radial-gradient(
//     50% 50% at 50% 50%,
//     var(--top-gradient) 60%,
//     transparent 100%
//   );
//   filter: blur(100px);
// }
// .bg-wrapper--on-rest:before {
//   background: radial-gradient(
//     50% 50% at 50% 50%,
//     var(--bottom-gradient) 60%,
//     transparent 100%
//   );
//   filter: blur(100px);
// }
// .bg-wrapper--on-exercise:after,
// .bg-wrapper--on-exercise:before {
//   content: "";
//   display: block;
//   position: absolute;
//   bottom: -500px;
//   left: -100px;
//   right: -100px;
//   height: 900px;
//   border-radius: 50%;
// }
// .bg-wrapper--on-exercise:after {
//   background: radial-gradient(
//     50% 50% at 50% 50%,
//     var(--bottom-gradient) 60%,
//     transparent 100%
//   );
//   filter: blur(100px);
// }

// .bg-wrapper--on-rest:after {
//   background: radial-gradient(
//     50% 50% at 50% 50%,
//     rgba(126, 255, 209, 0.6) 60%,
//     transparent 100%
//   );
//   filter: blur(100px);
// }
// .bg-wrapper--on-exercise {
//   background-color: rgba(255, 217, 82, 1);
// }
// .bg-wrapper--on-rest {
//   background-color: rgba(165, 255, 233, 1);
// }
// .bg-wrapper--on-setup-time {
//   background-color: var(--main-bg);
// }

.loading-bg {
  position: absolute;
  left: 0;
  right: 32px;
  top: 0;
  bottom: 0;
  background: var(--loader-bg);
  display: block;
  width: 0;
  height: 100vh;
  opacity: 1;
  transition: width 100ms linear, background-color 200ms linear;
}
// .loading-bg--on-rest {
//   /* background-color: rgba(131, 233, 239, 0.5); */
//   transform: skewY(20deg);
//   top: -55vh;
// }
// .loading-bg--on-exercise {
//   /* background-color: rgba(255, 241, 114, 0.5); */
//   transform: skewY(-20deg);
//   top: 50vh;
// }
// .loading-bg--on-start {
//   display: none;
// }
// .loading-bg--on-setup-time {
//   opacity: 0;
// }
