.tooltip {
  /* I'm so sorry */
  background: transparent !important;
  opacity: 1 !important;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  margin-top: -5px !important;
  color: var(--ui-main) !important;
}
.tooltip:after {
  display: none;
}
