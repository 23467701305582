.main-content {
  // height: 100vh;
  /* mobile viewport bug fix */
  // min-height: -webkit-fill-available;

  position: relative;
  max-width: 840px;
  margin: 0 auto;
  max-height: 890px;
  z-index: 1;
}

.layout {
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  gap: 16px;
  justify-items: stretch;
  grid-template-areas:
    "actions--top"
    "content"
    "actions--bottom";
  position: absolute;
  padding: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
