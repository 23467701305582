.button {
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: var(--small-text-size);
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  font-family: "Rubik", sans-serif;
}
.button:only-child {
  margin: 0 auto;
}
.button:disabled {
  opacity: 0.4;
}
a.button {
  text-decoration: none;
  color: var(--secondary-text);
}
.button--main {
  width: 80px;
  height: 80px;
}
.button--main .button__icon,
.button--secondary .button__icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.button--text {
  padding: 20px 32px 18px 32px;
  color: var(--secondary-text);
  background: transparent;
  border-radius: 4px;
  transition: all 100ms;
}
.button--text:hover {
  background: white;
  box-shadow: 0 1px 1px rgba(38, 41, 57, 0.08), 0 2px 2px rgba(38, 41, 57, 0.07),
    0 4px 4px rgba(38, 41, 57, 0.05);
}
