.actions {
  // width: clamp(400px, 50vw, 500px);
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  bottom: 0;
  padding: 3vh 0;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-items: center;
  justify-content: space-between;
}
// .actions--gradient:before {
//   content: "";
//   height: 50px;
//   position: absolute;
//   background: linear-gradient(
//     0deg,
//     var(--main-bg) 10%,
//     var(--main-bg) 35%,
//     rgba(var(--main-bg), 0) 100%
//   );
//   top: -58px;
//   left: 0;
//   right: 0;
// }
.actions--top {
  grid-area: actions--top;
}
.actions--bottom {
  grid-area: actions--bottom;
  padding-top: 4vh;
}
