.app {
  --main-text-size: clamp(34px, 5vw, 56px);
  --secondary-text-size: clamp(28px, 4.5vw, 42px);
  --tertiary-text-size: clamp(18px, 4.5vw, 22px);
  --body-text-size: 16px;
  --small-text-size: clamp(14px, 1.5vw, 16px);
  --main-text-weight: 500;
  --secondary-text-weight: 400;
  --tertiary-text-weight: 500;
  --small-text-weight: 500;
}

.theme--light {
  --main-text-size: clamp(34px, 5vw, 56px);
  --secondary-text-size: clamp(28px, 4.5vw, 42px);
  --tertiary-text-size: clamp(18px, 4.5vw, 22px);
  --body-text-size: 16px;
  --small-text-size: clamp(14px, 1.5vw, 16px);
  --main-text-weight: 500;
  --secondary-text-weight: 400;
  --tertiary-text-weight: 500;
  --small-text-weight: 500;
}
// .theme--dark {
// }
.theme--exercise--active {
  --main-text-size: clamp(34px, 5vw, 56px);
  --secondary-text-size: clamp(28px, 4.5vw, 42px);
  --main-text-weight: 500;
  --secondary-text-weight: 400;
}
.theme--rest--active {
  --main-text-size: clamp(28px, 4.5vw, 42px);
  --secondary-text-size: clamp(34px, 5vw, 56px);
  --main-text-weight: 400;
  --secondary-text-weight: 500;
}
.theme--starting--active {
  --main-text-size: clamp(28px, 4.5vw, 42px);
  --secondary-text-size: clamp(34px, 5vw, 56px);
  --main-text-weight: 400;
  --secondary-text-weight: 500;
}
.theme--final--active {
  --main-text-size: clamp(34px, 5vw, 56px);
  --secondary-text-size: clamp(28px, 4.5vw, 42px);
  --main-text-weight: 500;
  --secondary-text-weight: 400;
}
