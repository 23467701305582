.exercise-panel {
  padding: 4vh 16px 4vh 16px;
  position: relative;
  text-align: center;
  grid-area: content;
  display: grid;
  align-items: center;
  grid-template-rows: 120px 100px auto;
}

/* Up next */

.up-next {
  position: relative;
  bottom: 0;
}
.up-next__label {
  // margin-bottom: 8px;
  display: block;
  color: var(--secondary-text);
}

/* Timer */

.timer {
  font-size: 80px;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
  font-variant-numeric: tabular-nums;
  letter-spacing: -1px;
  height: 100px;
  line-height: 100px;
  color: var(--main-text);
}
.timer__fixed--hidden {
  display: none;
}
.timer__running--hidden {
  display: none;
}
