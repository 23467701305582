body {
  font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
  color: var(--main-text);
}

h1 {
  font-size: var(--main-text-size);
  font-weight: var(--main-text-weight);
  margin: 0;
  color: var(--main-text);
}
h2 {
  font-size: var(--secondary-text-size);
  font-weight: var(--secondary-text-weight);
  margin: 0;
  color: var(--main-text);
}
h3 {
  font-size: var(--tertiary-text-size);
  font-weight: var(--tertiary-text-weight);
  margin: 0;
  color: var(--main-text);
}
p {
  font-size: var(--body-text-size);
  margin: 0;
  color: var(--main-text);
}
.small {
  font-weight: var(--small-text-weight);
  font-size: var(--small-text-size);
  text-transform: uppercase;
  color: var(--secondary-text);
}
.header {
  text-align: center;
  padding: 16px;
  color: var(--main-text);
}
