.card {
  // width: clamp(400px, 50vw, 450px);
  display: grid;
  grid-template-rows: auto 1fr;
  max-width: 504px;
  width: 100%;
  margin: 0 auto;
  border-radius: 16px;
  grid-area: content;
  background: white;
  // box-shadow: 0 1px 1px rgba(38, 41, 57, 0.08), 0 2px 2px rgba(38, 41, 57, 0.07),
  //   0 4px 4px rgba(38, 41, 57, 0.05);
  box-shadow: 0 1px 1px rgba(38, 41, 57, 0.11),
    0 2px 2px rgba(38, 41, 57, 0.105), 0 4px 4px rgba(38, 41, 57, 0.1),
    0 8px 8px rgba(38, 41, 57, 0.095), 0 16px 16px rgba(38, 41, 57, 0.09),
    0 32px 32px rgba(38, 41, 57, 0.08);
  min-height: 0;
  min-width: 0;
}
.card__header {
  padding: 24px 40px;
  border-bottom: 1px solid var(--ui-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__body {
  min-height: 0;
  min-width: 0;
}
